import { Row, Card, Col, Modal, Checkbox, Button } from 'antd'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { getbookingCancel } from '../../services/EndPoint';
import moment from 'moment';

const HotelCancellationModal = ({ item, handleOk, handleCancel, setRender, isModalVisible }) => {
    const [isChecked, setIsChecked] = useState(false);
    const [cancelReq, setCancelReq] = useState(null);

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };


    const fetchBookingCancel = () => {
        const payload = {
            booking_id: item?.booking_id
        };

        getbookingCancel(payload)
            .then((response) => {
                setCancelReq(true)
                setTimeout(() => {
                    setCancelReq(false);
                    handleCancel()
                    setRender(response)
                }, 2000);
            })
            .catch((error) => {
                console.error("Error fetching bookings:", error);
            });
    };

    return (
        <div>
            <Modal
                // open={true}
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={false}
                width={520}
                centered
            >


                <div className="">
                    <Row gutter={[16, 16]} justify="center">
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Card className="booking-card w-100 p-4" style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '8px' }}>
                                {!cancelReq ? (
                                    <>
                                        <div className="mb-4">
                                            <h3 className="booking-title my-3" style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>Cancel Booking</h3>
                                            <hr style={{ width: '50px', margin: '0 auto' }} />
                                        </div>

                                        <p style={{ fontSize: '1rem', color: '#333' }}>
                                            Your booking at <strong>The Superb Hotel</strong> from{" "}
                                            <strong>{moment(item?.booking_from).format('DD MMM, YYYY')}</strong> to{" "}
                                            <strong>{moment(item?.booking_to).format('DD MMM, YYYY')}</strong> will be canceled.
                                        </p>

                                        <p className="mt-3" style={{ fontSize: '1rem', color: '#666' }}>
                                            Are you sure you want to proceed?
                                        </p>

                                        <p style={{ fontSize: '1rem', color: '#666' }}>
                                            According to the cancellation policy{" "}
                                            <Link
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    window.open('/payment-policy', '_blank');
                                                }}
                                                style={{ color: '#1890ff' }}
                                            >
                                                Payment & Cancellation Policy
                                            </Link>
                                        </p>

                                        <p style={{ fontSize: '1rem', color: '#666' }}>
                                            <Checkbox onChange={handleCheckboxChange} className="mr-2" /> {" "}
                                            I Acknowledge That I Have Read And Understand The Payment & Cancellation Policy.
                                        </p>

                                        <div className="text-center">
                                            <Button className='mx-2 cancellation-button' type="primary" onClick={() => handleCancel()}>Go Back</Button>
                                            <Button className="cancellation-button" type="primary" disabled={!isChecked} onClick={fetchBookingCancel}>
                                                Confirm Cancellation
                                            </Button>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <h6 className="px-4 mt-4" style={{ fontSize: '1.2rem', color: '#333' }}>
                                            Your cancellation request has been sent to your email. Please check your inbox.
                                        </h6>
                                        <Button
                                            className="mx-2 cancellation-button mt-4"
                                            type="default"
                                            onClick={() => handleCancel()}
                                            style={{ borderRadius: '5px', padding: '0.5rem 1.5rem' }}
                                        >
                                            Go Back
                                        </Button>
                                    </>
                                )}
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </div>
    )
}

export default HotelCancellationModal