import React, { useEffect, useState } from 'react'
import { fetchOfferList } from '../../services/EndPoint';
import moment from 'moment';


const Offers = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        setLoading(true);
        fetchOfferList()
            .then((req) => {
                setLoading(false);
                setData(req?.data?.offers);
            })
            .catch((err) => {
                console.log("Error fetching bookings:", err);
                setLoading(false);
            });
    }, []);


    return (

        <>
            {data?.length > 0 ? (
                data.map((offer, i) => (
                    <div className="col-md-4" key={i}>
                        <div className="offer_cntr">
                            <div className="ticket">
                                <div className="ticket--start">
                                    <p style={{ transform: "rotate(270deg)" }}>Coupon</p>
                                </div>
                                <div className="ticket--center">
                                    <div className="ticket--center--row">
                                        <div className="ticket--center--col">
                                            <div className="code">
                                                <p className="mb-0">
                                                    <span>Code:</span> <b>{offer.offer_code}</b>
                                                </p>
                                                <span>
                                                    {moment(offer.offer_valid_to).format("DD MMM, YYYY")}
                                                </span>
                                            </div>

                                            <p className="mb-0 lh-sm">
                                                {offer.offer_value}
                                                {offer.offer_value_type === "percent" ? "%" : ""}
                                                {offer.offer_value_type === "fixed" ? " off" : ""}
                                                {" "}on {offer.offer_type.toLowerCase()}.
                                            </p>
                                        </div>
                                        <hr />
                                    </div>
                                </div>

                                <div className="ticket--end">
                                    <div>
                                        <i className="bx bxs-buildings"></i>
                                    </div>
                                    <div>
                                        <i className="bx bxs-offer"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            ) : (

                <div className="col-md-12 text-center no-offer-message ">
                    <div className="alert alert-info p-5">
                        <h5>No Offers Available</h5>
                        <p>Currently, there are no available offers. Please check back later !</p>
                    </div>
                </div>
            )}


















            {/* <div className='col-md-4'>
                <div className='offer_cntr'>
                    <div class="ticket">
                        <div class="ticket--start">
                            <p style={{ rotate: "270deg" }}>
                                Coupon
                            </p>
                        </div>
                        <div class="ticket--center">
                            <div class="ticket--center--row">
                                <div class="ticket--center--col">
                                    <div className='code'>
                                        <p className='mb-0'><span>Code:</span> <b>HOTEL</b></p>
                                        <span>31 Aug, 2024</span>
                                    </div>
                                    <p className='mb-0 lh-sm'>
                                        Grab a maximum 2% discount up to Rs. 200, valid for all bookings
                                    </p>
                                </div>
                                <hr />
                                <a href='#'>View more</a>
                            </div>
                        </div>
                        <div class="ticket--end">
                            <div>
                                <i class='bx bxs-buildings'></i>
                            </div>
                            <div>
                                <i class='bx bxs-offer'></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )

}

export default Offers